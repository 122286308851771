import React from 'react'
import PropTypes from 'prop-types'
import MenuItem from './menuItem'
import * as S from './styled'

const MenuDesktopPlain = ({ menus, location,itemsDropDown }) => (
  <>
    <S.ItemsMenuDasaNavDesktop>
      {menus.map((menu, index) => (
        <MenuItem key={index} menu={menu} url={location} itemsDropDown={itemsDropDown}/>
      ))}
    </S.ItemsMenuDasaNavDesktop>
  </>
)

MenuDesktopPlain.propTypes = {
  menus: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string,
      description: PropTypes.string,
      wordpress_children: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          url: PropTypes.string,
          description: PropTypes.string,
        })
      ),
    })
  ),
}

export default MenuDesktopPlain
