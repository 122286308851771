import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ModalNavContext from './contexts/ModalNavContext'
import { ThemeProvider, theme } from '@dasa-health/alma-react'
import { ThemeProvider as StyledThemeProvider } from 'styled-components' 
const Theme = ({ children }) => {

  const [timerModalNav, setTimerModalNav] = useState(false)

  return (
    <ThemeProvider>
      <StyledThemeProvider theme={theme}>
        <ModalNavContext.Provider  value={{timerModalNav, setTimerModalNav}}>
          {children}
        </ModalNavContext.Provider>
        </StyledThemeProvider>
    </ThemeProvider>
  )
}

Theme.propTypes = {
  children: PropTypes.element,
}

export default Theme
