const getUrlsByCountry = country => {
  let urls = {
    production: '',
    examsSearch: '',
    specialty: '',
    backoffice: '',
    units: '',
    cities: '',
    total: '',
    specialtyAreastotal: ''
  }

  switch (country) {
    case 'BR':
      urls.production = process.env.GATSBY_GENEONE_URL
      urls.backoffice = process.env.GATSBY_BACKOFFICE_URL
      urls.examsSearch = process.env.GATSBY_SEARCH_EXAM_BR
      urls.specialty = process.env.GATSBY_SEARCH_FILTROS_SPECIALTY_BR
      urls.units = process.env.GATSBY_GET_UNITS
      urls.cities = process.env.GATSBY_GET_CITIES
      urls.total = process.env.GATSBY_GET_TOTAL_EXAMS
      urls.specialtyAreastotal = process.env.GATSBY_GET_TOTAL_SPECIALTY_AREAS
      break

    case 'AR':
      urls.production = process.env.GATSBY_GENEONE_URL_AR
      urls.backoffice = process.env.GATSBY_BACKOFFICE_URL_AR
      urls.examsSearch = process.env.GATSBY_SEARCH_EXAM_AR
      urls.specialty = process.env.GATSBY_SEARCH_FILTROS_SPECIALTY_AR
      urls.units = process.env.GATSBY_GET_UNITS
      urls.cities = process.env.GATSBY_GET_CITIES
      urls.total = process.env.GATSBY_GET_TOTAL_EXAMS_AR
      urls.specialtyAreastotal = process.env.GATSBY_GET_TOTAL_SPECIALTY_AREAS_AR
      break

    default:
      urls.production = process.env.GATSBY_GENEONE_URL
      urls.backoffice = process.env.GATSBY_BACKOFFICE_URL
      urls.examsSearch = process.env.GATSBY_SEARCH_EXAM_BR
      urls.specialty = process.env.GATSBY_SEARCH_FILTROS_SPECIALTY_BR
      urls.units = process.env.GATSBY_GET_UNITS
      urls.cities = process.env.GATSBY_GET_CITIES
      urls.total = process.env.GATSBY_GET_TOTAL_EXAMS
      urls.specialtyAreastotal = process.env.GATSBY_GET_TOTAL_SPECIALTY_AREAS
      break
  }

  return urls
}

module.exports = getUrlsByCountry
