export const getSpecialtyAreasWithQuantity =  (listItemsDropDown = [],listSpecialtyAreas = []) =>{

    let arrayNamesItensDropDown = listItemsDropDown.map(function(item) {
        return String(item[1]).toLowerCase();
    });

    let uniqueNames = {};
    let arrayNamesWithoutDuplicates = arrayNamesItensDropDown.filter(function(name) {
    if (!uniqueNames[name]) {
        uniqueNames[name] = true;
        return true; 
    }
    return false; 
    });

    
    let resultListSpecialty = listSpecialtyAreas.filter(function(specialty) {
        return arrayNamesWithoutDuplicates.indexOf(String(specialty[0]).toLowerCase()) !== -1;
    });
    
    return resultListSpecialty ?? [];
};