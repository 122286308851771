import React from 'react'
import * as S from './styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

const CardLink = ({ title, description = '', url = '' }) => {
  return (
    <S.LinkCardTo to={url}>
      <S.ContainerCardLink className='cnt-cardlink'>
        <S.ContentCardLink className='textContentCardLink'>
          <S.TitleCardLink>{title}</S.TitleCardLink>
          <S.DescriptionCardLink>{description}</S.DescriptionCardLink>
        </S.ContentCardLink>
        <S.IconContentCardLink className='iconContentCardLink'>
          <FontAwesomeIcon
            onClick={() => {}}
            color="#222222"
            title="Fechar"
            size="2x"
            icon={faAngleRight}
          />
        </S.IconContentCardLink>
      </S.ContainerCardLink>
    </S.LinkCardTo>
  )
}

export default CardLink
