import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import MenuDesktopPlain from './MenuDesktopDasa'

const MenuDesktopDasa = props => {
  const data = useStaticQuery(graphql`
    query {
      wordpressGeneoneThemeHeader {
        sidebars {
          widgets {
            value {
              items {
                title
                description
                url
                wordpress_children {
                  object_id
                  title
                  description
                  url
                }
              }
            }
          }
        }
      }
      allWordpressWpGatsbyPage(filter: { slug: { in: ["exames", "examenes"] } }) {
        nodes {
          title
          slug
          acf {
            text1_dropdown_menu
            text2_dropdown_menu
            text3_dropdown_menu
            text4_dropdown_menu
            text5_dropdown_menu
            text6_dropdown_menu
            text7_dropdown_menu
            text8_dropdown_menu
            text9_dropdown_menu
          }
        }
      }
    }
  `)

  const menus = data.wordpressGeneoneThemeHeader.sidebars[0].widgets[0].value.items

  const itemsDropDown = data.allWordpressWpGatsbyPage.nodes[0].acf

  return <MenuDesktopPlain menus={menus} location={props} itemsDropDown={itemsDropDown} />
}

export default MenuDesktopDasa
