import Img from 'gatsby-image'
import styled from 'styled-components'
import { Link } from 'gatsby'
import media from 'styled-media-query'

export const FooterSocialHolder = styled.div`
  padding-top: 7rem;
  padding-bottom: 4rem;
  display: grid;
  gap: 0px 5.6rem;

  ${media.greaterThan('medium')`
    & > *:last-child {
      grid-column: 5 / 6;
    }
  `}

  grid-template-columns: 3fr 2fr;

  ${media.lessThan('medium')`
    grid-template-columns: 1fr 1fr;
    padding-top: 3rem;
  `}
`

export const SocialImage = styled(Img)``

export const SocialImageLink = styled(Link)``

export const SocialImageHolder = styled.div`
  max-width: 162px;
  display: inline-block;
`

export const SocialLinksHolder = styled.div`
  ${media.lessThan('medium')`
    display: flex;
    align-items: center;
    height: 4.9rem;
  `}
`

export const SocialLinksListHolder = styled.ul`
  display: flex;
  justify-content: ${props => props.alignitem ? props.alignitem : 'flex-end'};
  padding-left: 0;

  ${media.lessThan('medium')`
    margin: 0;
    padding: 0;
  `}
`
export const ListItem = styled.li`
  display: inline-block;
  margin-right: 3.2rem;
  
  &:last-child {
    margin-right: 0;
  }

  ${media.lessThan('medium')`
    margin-right: 2.4rem;
  
    &:last-child {
      margin-right: 0;
    }
  `}
`
export const ListItemLink = styled.a``

export const ListItemIcon = styled.i`
  color: #D12673;
  width: 3.6rem;
  height: 3.6rem;
  font-size: 24px;
  transition: 0.3s;
  width: 22px;
  height: 25px;

  &:hover {
    color: var(--pink);
  }

  svg {
    ${media.lessThan('medium')`
      width: 16px;
      height: 19px;
    `}
  }
`

export const FooterText = styled.p`
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  grid-area: text;
  margin: 1.6rem 0 0;

  ${media.lessThan('medium')`
    font-size: 12px;
    line-height: 170%;
    margin: .8rem 0 0;
  `}
`
