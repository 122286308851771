import React from 'react'
import PropTypes from 'prop-types'
import IconWrapper from '../../../../../shared/IconWrapper'
import { getAlmaIconByName } from '../../../../../shared/getAlmaIconByName'
import { getLinkByIcon } from '../../../../../../utils/getLinkByIcon'

import * as S from './styled'

const ContactInfo = props => {
  const Icon = getAlmaIconByName(props.icon)
  const Link = getLinkByIcon(props.icon, props.title)
  
  return (
    <S.ContactInfoHolder key={props.key ?? Math.random()}>
      <S.ContactInfoTextHolder>
        <S.ContactInfoTitle lowerwords={!props.text && !props.subtext}>
          <S.ContactInfoIconHolder>
            {Icon !== null && (
              <S.ContactInfoIcon>
                <IconWrapper Icon={Icon} size="22" />
              </S.ContactInfoIcon>
            )}

            <S.Contact 
              href={Link} 
              target="_blank"
            >
              {props.title}
            </S.Contact>
          </S.ContactInfoIconHolder>
        </S.ContactInfoTitle>
        {props.text && <S.ContactInfoText>{props.text}</S.ContactInfoText>}
        {props.subtext && <S.ContactInfoSubtext>{props.subtext}</S.ContactInfoSubtext>}
      </S.ContactInfoTextHolder>
    </S.ContactInfoHolder>
  )
}

ContactInfo.propTypes = {
  text: PropTypes.string,
  subtext: PropTypes.string,
  title: PropTypes.string,
}

export default ContactInfo
