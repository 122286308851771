import React from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import VerticalSubMenuDasa from './verticalSubMenuDasa'
import { ChevronDown } from '@dasa-health/alma-icons'
import * as S from './styled'
import { IsExamsProvider } from '../../../utils/isExamsContext'

const DropdownMenu = ({ menu, itemsDropDown }) => {
  const handleItemClick = () => {
    const redirectTo = menu.url
    navigate(redirectTo)
  }

  return (
    <S.MenuItemDasaNavDesktop data-testid="dropdown-menu" className="mainMenuDropdown">
      <S.MenuDasaToggler data-testid="dropdown-menu-button" onClick={handleItemClick}>
        {menu.title}
        <S.ArrowIconContainer>
          <ChevronDown />
        </S.ArrowIconContainer>
      </S.MenuDasaToggler>

      <IsExamsProvider>
        <VerticalSubMenuDasa submenus={menu.wordpress_children} itemsDropDown={itemsDropDown} />
      </IsExamsProvider>
    </S.MenuItemDasaNavDesktop>
  )
}

DropdownMenu.propTypes = {
  menu: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string,
    submenus: VerticalSubMenuDasa.propTypes.submenus,
  }),
}

export default DropdownMenu
