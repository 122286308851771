import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import VerticalSubmenu from './verticalSubmenu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Search } from '@dasa-health/alma-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import * as S from './styled'
import { ContainerIconClose } from '../../shared/Modal/styled'
import CardLink from '../../shared/CardLink'
import { LinkExams } from '../../title/styled'

import { useIsExamsContext } from '../../../utils/isExamsContext'
import { getTotalSpecialtyApi } from '../../../services/laravelService'
import { getSpecialtyAreasWithQuantity } from '../../../utils/getSpecialtyAreasWithQuantity'
import getSlugByCountry from '../../../utils/getSlugByCountry'

const SubmenuItem = ({ submenu, itemsDropDown }) => {
  const hasSubmenu = submenu.submenus && submenu.submenus.length
  const [submenuOpen, setSubmenuOpen] = useState(false)
  const [descriptionOpen, setDescriptionOpen] = useState(false)
  

  return (
    <S.SubMenuItemsDropdownDasa
      key={`submenu_${submenu.title}`}
      onMouseOver={() => {
        setSubmenuOpen(true)
        setDescriptionOpen(true)
      }}
      onMouseLeave={() => {
        setSubmenuOpen(false)
        setDescriptionOpen(false)
      }}
      to={submenu.url}
    >
      <S.ItemLinkDropdownDasa>
        {submenu.title}
        {descriptionOpen && submenu.description !== '' && (
          <S.DescriptionLinkDropdownDasa>{submenu.description}</S.DescriptionLinkDropdownDasa>
        )}
      </S.ItemLinkDropdownDasa>

      {hasSubmenu && submenuOpen ? (
        <VerticalSubmenu submenu={submenu.submenus} itemsDropDown={itemsDropDown} />
      ) : null}
    </S.SubMenuItemsDropdownDasa>
  )
}

const VerticalSubMenuDasa = ({ submenus, itemsDropDown }) => {
   const [error, setError] = useState({
        exams: false,
        specialtyAreastotal: false
      })
      const handleApiError = (routeName, error) => {
        setError(prevState => ({
          ...prevState,
          [routeName]: error
        }))
      }
  
  
  const { getExamsTotal, totalExams } = useIsExamsContext();
  const [orderedItemsDropDown, setOrderedItemsDropDown] = useState([])
  const examsPageSlug = getSlugByCountry('exames')
  const country = process.env.GATSBY_COUNTRY ;

  const  getListTotalspecialty = async ()=> {
    try {
      const listSpecialtyAreasWithQuantity = await getTotalSpecialtyApi(error,handleApiError)
   
      const resultListSpecialtyAreasWithQuantity = getSpecialtyAreasWithQuantity(Object.entries(itemsDropDown),Object.entries(listSpecialtyAreasWithQuantity))
      
      setOrderedItemsDropDown(resultListSpecialtyAreasWithQuantity ?? [])
      
    } catch (error) {
      console.error('Erro na requisição:', error.message)
    }
  }

  const toggleMenuClose = () =>{
    const mainMenu = document.querySelector(".mainMenuDropdown");
    
    mainMenu.classList.toggle('noHover');
    
    setTimeout(()=>{
      mainMenu.classList.remove("noHover")
    },50)
  }

  useEffect(() => {

    getListTotalspecialty()
    
    getExamsTotal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsDropDown])  

  return (
    <S.DropdownVertical data-testid="dropdown-menu-open" className="menuDropdown">
      <S.ContainerTitleItemLinkDropdownDasa>
        <ContainerIconClose>
          <FontAwesomeIcon
            onClick={toggleMenuClose}
            color="#02103F"
            title="Fechar"
            size="lg"
            icon={faTimes}
          />
        </ContainerIconClose>
      </S.ContainerTitleItemLinkDropdownDasa>
      <S.ContainerItemLinkDropdownDasa>
        <S.ContainerContentLinkDropdownDasa>
          <S.ContainerCardItemLinkDropdownDasa>
            {submenus.slice(0,6).map(submenu => (
              <CardLink title={submenu.title} description={submenu.description} url={submenu.url} />
            ))}
          </S.ContainerCardItemLinkDropdownDasa>
          <S.ContainerLinkExams country={country}>
            <LinkExams to={`/${examsPageSlug}`}>
              <Search />
              { country === "BR" ? 'Ver todos os exames' : 'Ver todos los exámenes'} ({totalExams})
            </LinkExams>
          </S.ContainerLinkExams>
        </S.ContainerContentLinkDropdownDasa>
        <S.ContainerListEspecialidadesDropdownDasa>
          <S.TitleEspecialidadesDropdownDasa>
            { country === "BR" ? 'Exames por área de atuação' : 'Exámenes por área de actividad'}
          </S.TitleEspecialidadesDropdownDasa>
          <S.ContainerEspecialidadesDropdownDasa>
            {orderedItemsDropDown.map(itemDropDown => (
              <S.ItemEspecialidadesDropdownDasa to={`/${examsPageSlug}`} onClick={() => {
                  localStorage.setItem("specialtyAreaSelected", itemDropDown[1].id)
              }}>
                {itemDropDown[0]} ({itemDropDown[1].quantidade})
              </S.ItemEspecialidadesDropdownDasa>
            ))}
          </S.ContainerEspecialidadesDropdownDasa>
        </S.ContainerListEspecialidadesDropdownDasa>
      </S.ContainerItemLinkDropdownDasa>
    </S.DropdownVertical>
  )
}

const submenuType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
  submenus: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string,
      submenus: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        })
      ),
    })
  ),
})

VerticalSubMenuDasa.propTypes = {
  submenus: PropTypes.arrayOf(submenuType),
}

SubmenuItem.propTypes = {
  submenu: submenuType,
}

export default VerticalSubMenuDasa
