import styled from 'styled-components'
import media from 'styled-media-query'

export const ContainerStamp = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 40px;
    align-items: center;
    width: 100%;
    margin-top: 40px;

     ${media.lessThan('medium')`
       justify-content: flex-start;
    `}
`