import { Link } from 'gatsby';
import styled from 'styled-components';
import media from 'styled-media-query'

export const LinkCardTo = styled(Link)`
    text-decoration: none;

     ${media.lessThan('550px')`
        width: 98%;
      `}


`

export const ContainerCardLink = styled.div`
    height: 148px;
    width: 280px;
    padding: 16px;
    border: 1px solid;
    border-color: rgba(22, 22, 22, 0.08);
    border-radius: 8px;
    display:flex;
    box-sizing: content-box;
    background-color: #fefefe;
    gap: 16px;

    &:hover {
        background-color: #000E40;
        color: #fefefe;
        border-color: none;

        h2,p,svg{
            color: #fefefe;
        }
        
    }

    ${media.lessThan('medium')`
        width: 95%;
        height: 100%;
      `}


`;
export const ContentCardLink = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
`;
export const IconContentCardLink = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
    height: 24px;
    width:24px;
    align-self: center;
`;

export const TitleCardLink = styled.h2`
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #000E40;
    font-family: 'Dasa Sans';
`;
export const DescriptionCardLink = styled.p`
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: #363636;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5; 
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-family: 'Dasa Sans';
`;