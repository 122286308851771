import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import GroupLinkMobile from './GroupLinkMobile'
import GroupContactUs from '../GroupLinks/GroupContactUs'
import GroupNamContact from '../GroupLinks/GroupNamContact'

import * as S from './styled'

const GroupLinksMobile = () => {
  const data = useStaticQuery(graphql`
    query {
      wordpressGeneoneThemeFooter {
        sidebars {
          name
          widgets {
            type
            value {
              rendered
              items {
                title
                url
                object_id
              }
              name
            }
          }
        }
      }
    }
  `)

  const {
    wordpressGeneoneThemeFooter: { sidebars },
  } = data

  function findWidgetNam() {
    let side = sidebars.find(function (side) {
      return side.name === "NAM"
    })

    return side
  }

  return (
    <S.GroupLinksDivMobile>
      {
        sidebars.map((link, index )=> {
          if (link.widgets === null) {
            return <></>
          }

          if (link.name === "NAM") return null

          if (link.name === 'Contato') {
            if (link.widgets.length === 1) return null
            return <GroupContactUs data={link} key={index}/>
          }

          if (link.name === 'Área Médica') {
            const items = []
            const nam = findWidgetNam()

            link.widgets.map((widget) => {
              if (widget.value?.items?.length > 0) {
                return widget.value.items.map((item) => items.push(item))
              }

              return true
            })

            if (nam && nam.widgets !== null) {
              const item = {
                object_id: Math.floor(Math.random() * 99999) + 1,
                title: nam.name,
                url: "#",
                content: <GroupNamContact data={nam} />
              }

              items.push(item)
            }

            const link_nav = [
              {
                type: "nav_menu",
                value: {
                  name: link.name,
                  items: items
                }
              }
            ]

            return link_nav.map((nav, index) => {
              return (
                <GroupLinkMobile
                  key={`linkMobile_${Math.random()}_${index}`}
                  textHeader={nav.value.name}
                  links={nav.value.items}
                  class="nam"
                />
              )
            })
          }

          return link.widgets.map((widget, index) => {
            return (
              <GroupLinkMobile
                key={`link_mobile_${Math.random()}_${index}`}
                textHeader={widget.value.name || ''}
                links={widget.value.items || []}
              />
            )
          })
        })
      }
    </S.GroupLinksDivMobile>
  )
}

export default GroupLinksMobile
