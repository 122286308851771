import React, { createContext, useContext, useState } from 'react';
import { getExamsApi, getSpecialtyApi, getTotalExams } from '../services/laravelService'

const IsExamsContext = createContext();

export const IsExamsProvider = ({ children }) => {
    const [error, setError] = useState({
      exams: false,
      specialty: false
    })
    const handleApiError = (routeName, error) => {
      setError(prevState => ({
        ...prevState,
        [routeName]: error
      }))
    }
    const [examsData, setExamsData] = useState(false)
    const [totalExams, setTotalExams] = useState(0)
    const [specialtyData, setSpecialtyData] = useState(false)
    const [paginationData, setPaginationData] = useState({pageNumber: 1, pagesCount: false})
    const [searchText, setSearchText] = useState('initial_param')
    const [ansFilter, setAnsFilter] = useState('initial_param')
    const [specialtySelected, setSpecialtySelected] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [firstRender, setFirstRender] = useState(true)

    const getExamsList = async (pageNumber = false) => {
      const examsListData = await getExamsApi(
        searchText,
        pageNumber ? pageNumber : paginationData.pageNumber,
        specialtySelected,
        ansFilter,
        error,
        handleApiError
      );

      setExamsData(examsListData.data)
      setPaginationData(paginateData => ({...paginateData, pagesCount: examsListData.meta.last_page}))
      setIsLoading(false)
    }

    const getExamsTotal = async () => {
      const totalExams = await getTotalExams(error, handleApiError)
      setTotalExams(totalExams.total)
    }

    const getSpecialtyAreas = async () => {
      const result = await getSpecialtyApi(error, handleApiError)
      const resultOrderAsc = result.sort((a, b) => a.nome.localeCompare(b.nome))
      setSpecialtyData(resultOrderAsc)
    }

    const ansFilterOptions = [
      {
        id: true,
        nome: 'Cobertura ANS'
      },
      {
        id: false,
        nome: 'Não Coberto ANS'
      }
    ]

    const handleChangeCheckboxFilter = (value, attribute) =>  {
      if(attribute === 'specialty') {
        setSpecialtySelected((specialtySelected) => {
          if (specialtySelected.includes(value)) {
            return specialtySelected.filter((selectedId) => selectedId !== value);
          } else {
            return [...specialtySelected, value];
          }
        });
      }
  
      if(attribute === 'ans') {
        setAnsFilter((ansFilter) => {
          if(ansFilter === value) {
            setAnsFilter('initial_param')
          } else {
            setAnsFilter(value)
          }
        })
      } 
    }
  
    const contextValue = { 
      examsData,
      setExamsData,
      paginationData,
      setPaginationData,
      searchText,
      setSearchText,
      ansFilterOptions,
      ansFilter,
      setAnsFilter,
      isLoading,
      setIsLoading,
      firstRender,
      setFirstRender,
      error,
      setError,
      getExamsList,
      getSpecialtyAreas,
      specialtyData,
      setSpecialtyData,
      specialtySelected,
      setSpecialtySelected,
      handleChangeCheckboxFilter,
      getExamsTotal,
      totalExams
    }
  
    return (
      <IsExamsContext.Provider value={contextValue}>
        {children}
      </IsExamsContext.Provider>
    )
}
  
export const useIsExamsContext = () => {
    const context = useContext(IsExamsContext)

    if (!context) {
        throw new Error('useIsExamsContext must be used within a IsExamsProvider');
    }

    return context
}
