import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import * as S from './styled'


const Stamps = () => {

  const data = useStaticQuery(graphql`
    query {
      stamps: allFile(
        filter: { 
          sourceInstanceName: { eq: "images" }, 
          relativePath: { in: ["cap.jpg", "palc.jpg"] } 
        }
      ) {
        edges {
          node {
            id
            name
            publicURL
          }
        }
      }
    }
  `)

  const listStamps = data.stamps.edges

  return (

    <S.ContainerStamp>
      {
        listStamps.map((item, index) =>
        (
          <div>
            {
              index === 0 ?
                <img key={index} width={150} height={84} src={item.node.publicURL} alt={item.node.name} />
                :
                <img key={index} style={{ marginRight: 20 }} width={100} height={100} src={item.node.publicURL} alt={item.node.name} />
            }
          </div>
        )
        )
      }
    </S.ContainerStamp>

  )
}

export default Stamps;



