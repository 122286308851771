import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { Link } from 'gatsby'

export const ItemsMenuNavDesktop = styled.nav`
  display: flex;
  justify-content: space-around;
  -moz-box-flex: 1;
  flex-grow: 1;
  height: 3rem;
  margin: 0px 2rem;
  padding: 1rem 1rem 0px;

  ${media.lessThan('medium')`
    display: none;
  `}
`

export const ItemsMenuDasaNavDesktop = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 2rem 0 0 9.4rem;
  min-width: 557px;
  padding: 4px 0 0 0;

  ${media.lessThan('large')`
  display: none;
  `}

  @media (min-width:1171px) and (max-width:1274px) {
    margin: 2rem 0 0 5.4rem;
  }
`

export const ItemsSubMenuDasaNavDesktop = styled.nav`
  display: flex;
  min-height: 4.3rem;
  margin: 2rem 3.2rem 0 9.4rem;
  padding: 0.4rem 0 0 0;

  a {
    color: #61636b;
    margin-right: 3.2rem;

    @media (min-width: 1171px) and (max-width: 1274px) {
      margin-right: 2.3rem;
    }
  }

  ${media.lessThan('large')`
    display: none;
  `}

  @media (min-width:1171px) and (max-width:1274px) {
    margin: 2rem 3.2rem 0 4.4rem;
  }
`

export const MenuItem = css`
  font-size: 16px;
  margin: 0px;
  color: rgb(255, 255, 255);
  text-decoration: none;
  cursor: pointer;
`

export const MenuItemDasa = css`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  color: ${props => props.theme.colors.brand.primary[10]};
  text-decoration: none;
  margin-right: 3.2rem;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  @media (min-width: 1171px) and (max-width: 1274px) {
    margin-right: 3.8rem;
  }
`

export const MenuItemNavDesktopCommom = styled.a`
  ${MenuItem}

  &:hover {
    border-bottom: 0.2rem solid;
  }
`

export const MenuItemDasaNavDesktopCommom = styled(Link)`
  ${MenuItemDasa}

  &:hover {
    border-bottom: 0.2rem solid;
  }

  &.nav-dasa {
    color: ${props => props.theme.colors.palette.black};
    font-weight: 600;
    margin: 0;

    &:hover {
      border-bottom: none;
    }
  }

  &.active {
    border-bottom: 2px solid #012169;
  }
`

export const MenuItemDasaNavDesktopCommomExternal = styled.a`
  ${MenuItemDasa}

  &:hover {
    border-bottom: 0.2rem solid;
  }

  &.nav-dasa {
    color: ${props => props.theme.colors.palette.black};
    font-weight: 600;
    margin: 0;

    &:hover {
      border-bottom: none;
    }
  }

  &.active {
    border-bottom: 2px solid #012169;
  }
`

export const MenuItemNavDesktop = styled.span`
  ${MenuItem}
  position: relative;
`

export const MenuItemDasaNavDesktop = styled.div`
  ${MenuItemDasa}
  position: relative;

  &:hover {
    .menuDropdown {
      display: flex;
    }
  }

   &.noHover:hover .menuDropdown {
    display: none;
  }
`

export const MenuToggler = styled.span`
  display: block;
  height: 100%;
`

export const MenuDasaToggler = styled.span`
  display: flex;
  height: 4.05rem;

  &:hover {
    border-bottom: 0.2rem solid;
  }
`

export const DropdownContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
`

export const ItemLinkDropdown = styled.a`
  color: var(--pink);
  font-size: 16px;
  text-decoration: none;
  font-weight: 1000;
  position: relative;
`

export const ItemLinkDropdownDasa = styled.div`
  color: ${props => props.theme.colors.brand.primary[10]};
  font-size: 14px;
  text-decoration: none;
  font-weight: 500;
  position: relative;
  height: 25px;
  width: 100%;
`
export const ContainerItemLinkDropdownDasa = styled.div`
  width: 98%;
  height: 98%;
  display: flex;
  align-items: center;
  max-width: 1330px;

    @media (min-width: 1366px) {
      gap: 24px;
  }

`
export const ContainerTitleItemLinkDropdownDasa = styled.div`
  width: 98%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1230px;
`
export const TitleItemLinkDropdownDasa = styled.h1`
  width: 100%;
  height: 100%;
  color: #000e40;
  font-size: 1.4em;
  font-weight: 700;
  margin-bottom: 0.5em;
`

export const ContainerContentLinkDropdownDasa = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: 1366px) {
    max-width: 1000px;
  }

 
`

export const ContainerCardItemLinkDropdownDasa = styled.div`
  display: flex;
  align-items: center;
  align-self: start;
  width: 100%;
  flex-wrap: wrap;
  gap: 24px;
  max-width: 1210px;
`

export const ContainerEspecialidadesDropdownDasa = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 1em;
`
export const ContainerListEspecialidadesDropdownDasa = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  align-self: start;
  gap: 1em;
  height: 100%;
  width: 25%;
  padding-right: 24px;

  @media (max-width: 1345px) {
    width: 22%;
    padding-right: 16px;
  }
  @media (max-width: 1300px) {
    width: 18%;
  }
  @media (max-width: 1260px) {
    width: 17%;
  }
  @media (max-width: 1240px) {
    padding-right: 0em;
    width: 15%;
  }
  @media (max-width: 1200px) {
    width: 12%;
  }
`
export const ItemEspecialidadesDropdownDasa = styled(Link)`
  font-size: 16px;
  text-decoration: none;
  line-height: 150%;
  font-weight: 500;
  color: #161616;
  font-family: 'Dasa Sans';
`
export const TitleEspecialidadesDropdownDasa = styled.h1`
  font-size: 16px;
  line-height: 150%;
  font-family: 'Dasa Sans';
  font-weight: 700;
`

export const ContainerLinkExams = styled.div`
  width: ${props => props.country === "BR" ? "265px" : "305px" };
`

export const DescriptionLinkDropdownDasa = styled.p`
  color: ${props => props.theme.colors.brand.primary[10]};
  right: -110%;
  top: -115%;
  position: relative;
  display: inline-block;
  background-color: #fff;
  min-width: 300px;
  padding: 5px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  font-size: 12px;
`

export const SubitemsContainer = styled.div`
  display: flex;
  padding: 0;
`

export const SubMenuItemsDropdown = styled.div`
  padding: 2.4rem 0;
  margin: 0 auto;
`

export const SubMenuItemsDropdownDasa = styled(Link)`
  padding: 0.8rem 1.6rem;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;

  &:last-child {
    p {
      color: var(--pink);
      font-size: 14px;
      font-weight: 500;
      padding: 0;
    }

    &:hover {
      background-color: white;
    }
  }

  &:hover {
    background-color: #e8e8e8;
  }
`

export const DropdownArrow = styled.span`
  &::after {
    display: block;
    bottom: -0.5rem;
    border-right: 12px solid transparent;
    border-left: 12px solid transparent;
    border-bottom: 14px solid var(--white);
    content: '';
    height: 0;
    left: calc(50% - 12px);
    position: absolute;
    width: 0;
    bottom: -0.5rem;

    ${media.lessThan('huge')`
      bottom: -2.5rem;
    `}

    ${media.lessThan('1310px')`
      bottom: -2.9rem;
    `}
  }
`

export const Dropdown = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 0;
  top: 9.3rem;
  left: 0;
  width: 80vw;
  padding: 1rem 10%;
  margin: 0;
  background: var(--white);
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 0.175) 0px 6px 12px 0px;
`

export const DropdownVertical = styled.div`
  display: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 6.9rem;
  border-top: 1px solid #f3f3f3;
  left: 0;
  width: 97vw;
  margin: 0;
  background: var(--white);
  z-index: 100;
  box-shadow: 0px 2px 2px rgba(22, 22, 22, 0.04), 0px 4px 8px rgba(22, 22, 22, 0.08);
  border-radius: 4px;
  padding: 1.7em;
`

export const VerticalSubmenuWrapper = styled.div`
  position: fixed;
  text-align: center;
  background: var(--white);
  border-radius: 5px;
  color: var(--pink);
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-left: -1.2rem;
  margin-top: 2rem;
  min-width: 17rem;
  box-shadow: rgba(0, 0, 0, 0.175) 0px 6px 12px 0px;
`

export const VerticalSubmenuItem = styled(Link)`
  text-decoration: none;
  padding: 1rem 0;
  color: #7d7d7d;
  font-size: 16px;

  &:hover {
    color: var(--pink);
  }
`

export const ContactIconContainer = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: var(--white);
  letter-spacing: 0.1rem;
  margin-right: 2rem;

  ${media.lessThan('medium')`
    display: none;
    font-size: 14px;
  `}
`

export const ArrowIconContainer = styled.div`
  margin-left: 1rem;
  font-weight: bold;
`

export const SubMenuItemNavDasa = styled.div`
  display: flex;
  margin-right: 3.2rem;

  &:last-child {
    margin-right: 0;
  }
`
