import React, { useContext, useEffect, useState } from 'react'
import { Search } from '@dasa-health/alma-icons'
import { useStaticQuery, graphql } from 'gatsby'
import LogoNavWhite from '../../components/shared/LogoNavDasa/logoNavWhite'
import modalNav from '../BannerNav/modalNav'
import * as S from './styled'
import ModalNavContext from '../../utils/contexts/ModalNavContext'
import IllustrationImage from './illustrationImage'

const MainTitle = () => {
  const {timerModalNav, setTimerModalNav} = useContext(ModalNavContext)

  function openModalNav(e, modal) {
    if(timerModalNav) setTimerModalNav(false)

    modalNav(e, modal);
  }

  const data = useStaticQuery(graphql`
    query {
      wordpressWpGatsbyPage(slug: { eq: "home" }) {
        acf {
          nome_dasa
          titulo
          descricao
          link_description
          link_url
          periodo_inicial
          periodo_final
          link_banner
          btn_pink_title
          btn_pink_link
          illustration_home {
            alt_text
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          home_banner_img_desktop {
            alt_text
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          home_banner_img_mobile {
            alt_text
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
      modalResultsData: wordpressWpGatsbyPage(slug: {eq: "modal-results"}) {
        slug
        acf {
            results_modal_ativo
            results_modal_link
        }
      }
    }
  `)

  const {modalResultsData, wordpressWpGatsbyPage} = data

  const modalResults = modalResultsData?.acf
  const slugResults = modalResultsData.slug
  const homeData = wordpressWpGatsbyPage.acf
  const [bannerVisible, setBannerVisible] = useState(true);
  const titleDasa = homeData.titulo ? homeData.titulo.split(". ") : false
  
  useEffect(() => {
    const now = new Date()
    const firstPeriod = parseDate(homeData.periodo_inicial);
    const endPeriod = parseDate(homeData.periodo_final);

    function parseDate(dateString) {
      const [datePart, timePart] = dateString.split(' ');
      const [month, day, year] = datePart.split('-').map(Number);
      const [hours, minutes] = timePart.split(':').map(Number);
      return new Date(year, month - 1, day, hours, minutes);
    }
    
    function showBanner() {
      return now >= firstPeriod && now <= endPeriod;
    }

    return setBannerVisible(showBanner())
  }, [homeData])

  return (
    <S.ImgAbsoluteContainer>
      <S.TitleContainer>
        <S.TitleDasaContainer>
          <S.TitleDasa>
            {homeData.nome_dasa
            ?
              homeData.nome_dasa
            :
              "Dasa Genômica"
            }
          </S.TitleDasa>

          {titleDasa
          ?
            titleDasa.map((item) =>
              <S.SubTitle key={item}>
                {item}
              </S.SubTitle>
            )
          :
            <>
              <S.SubTitle>
                Medicina do futuro.
              </S.SubTitle>

              <S.SubTitle>
                Todos os dias.
              </S.SubTitle>
            </>
          }
        </S.TitleDasaContainer>

        <S.Paragraph>
          {homeData.descricao
          ?
            homeData.descricao
          :
            "Somos a medicina genômica da Dasa, a maior rede de saúde integrada do Brasil."
          }

          <S.AboutLink to={homeData.link_url ? homeData.link_url : "/sobre/"}>
            {homeData.link_description
            ?
              homeData.link_description
            :
              "Saiba mais."
            }
          </S.AboutLink>
        </S.Paragraph>

        <S.ButtonsDiv>
          <S.LinkExams to={homeData.btn_pink_link ? homeData.btn_pink_link : '/exames/'}>
            <Search />
            
            {homeData.btn_pink_title
            ?
              homeData.btn_pink_title
            :
              "Exames"
            }
          </S.LinkExams>

          {modalResults.results_modal_ativo && (
            <S.ResultsNav>
              <S.LinkNav
                onClick={e => openModalNav(e, slugResults)}
              >
                {modalResults.results_modal_link
                ?
                  modalResults.results_modal_link
                :
                  "Resultados"
                }
                <S.LogoNavWhiteDiv>
                  <LogoNavWhite />
                </S.LogoNavWhiteDiv>
                
              </S.LinkNav>
            </S.ResultsNav>
          )}
        </S.ButtonsDiv>
      </S.TitleContainer>

      {bannerVisible 
      ?
        <>
          <S.ImageContainer>
            <S.BannerImgContainer>
              <S.BannerLink href={homeData.link_banner}>
                <S.BannerImg
                  fluid={homeData.home_banner_img_desktop.localFile.childImageSharp.fluid} 
                  alt={homeData.home_banner_img_desktop.alt_text}
                />
              </S.BannerLink>  
            </S.BannerImgContainer>
          </S.ImageContainer>

          <S.BannerImgMobileContainer>
            <S.BannerLink href={homeData.link_banner}>
              <S.BannerImgMobile
                fluid={homeData.home_banner_img_mobile.localFile.childImageSharp.fluid} 
                alt={homeData.home_banner_img_mobile.alt_text}
              />
            </S.BannerLink>
          </S.BannerImgMobileContainer>
        </>
      :
        <IllustrationImage image={homeData.illustration_home} />
      }

    </S.ImgAbsoluteContainer>
  )
}

export default MainTitle
