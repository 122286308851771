import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'    
import AreaItem from './AreaItem'
import SwiperCore, { Pagination } from "swiper";
import { CarouselContainer } from '../../carousel/styled';
import { Swiper, SwiperSlide } from 'swiper/react';
import arrayPagination from '../../../utils/arrayPaginate';

import * as S from './styled'
import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.min.css'
import 'swiper/components/pagination/pagination.min.css'

SwiperCore.use([Pagination]);

const AreasMobile = ({homeData}) => {
    const data = useStaticQuery(graphql`
    query {
        allWordpressWpExpertiseAreaPage(sort: {fields: acf___ordem_mobile, order: ASC}) {
            edges {
                node {
                    acf {
                        link_atuacao
                        nome
                        icone {
                            alt_text
                            localFile {
                                childImageSharp {
                                    fluid(quality: 100) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                        ordem_mobile
                    }
                }
            }
        }
    }`)

    const AreaItens = data.allWordpressWpExpertiseAreaPage 
                        ?
                            arrayPagination(data.allWordpressWpExpertiseAreaPage.edges, 3)
                        :
                            ""

    return (
        <S.AreasMobileContainer>
            <S.AreasTitle>
                {homeData.areas_title ? homeData.areas_title : 'Áreas de atuação'}
            </S.AreasTitle>

            <CarouselContainer>
                <Swiper
                    spaceBetween={0}
                    slidesPerView={"auto"}
                    modules={[Pagination]}
                    pagination={{
                        clickable: true
                    }}
                >
                    {AreaItens.map((items,indexArea) => (
                        <SwiperSlide>
                            {items.map((item,index) => (
                                <AreaItem
                                    key={`${index}_${indexArea}`}
                                    title={item.node.acf.nome}
                                    icon={item.node.acf.icone}
                                    link={item.node.acf.link_atuacao}
                                />
                            ))}
                        </SwiperSlide>
                    ))}

                    <SwiperSlide
                        className="swiper-filler"
                    >
                    </SwiperSlide>
                </Swiper>
            </CarouselContainer>
        </S.AreasMobileContainer>
    )
}

export default AreasMobile
